<template>
  <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing">
    <div
      class="card-head box-shadow bg-white d-md-flex align-items-center justify-content-between p-15 p-sm-20 p-md-25"
    >
      <form class="search-box position-relative">
        <input
          type="text"
          class="form-control shadow-none text-black rounded-0 border-0"
          placeholder="Search order"
          v-model="searchQuery"
          @keyup="fetchInvoicesByRef()"
        />
        <button
          type="submit"
          class="bg-transparent text-primary transition p-0 border-0"
        >
          <i class="flaticon-search-interface-symbol"></i>
        </button>
      </form>
      <div class="d-sm-flex align-items-center">
      </div>
    </div>
    <div class="card-body p-15 p-sm-20 p-md-25">
      <div v-if="loading" class="d-flex align-items-center justify-content-center h-100">
        <div class="loading-spinner"></div>
      </div>
      <div v-else-if="!invoices.length" class="d-flex align-items-center justify-content-center h-100">
        <h2>No results Found</h2>
      </div>
      <div v-else class="table-responsive">
        <table class="table text-nowrap align-middle mb-0">
          <thead>
            <tr>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 ps-0"
              >
                Ref
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                Amount
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                Date
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                Status
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 ps-0"
              >
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="invoice in invoices" :key="invoice.id">
              <td class="shadow-none lh-1 fw-medium text-paragraph">
                {{invoice.ref}}
              </td>
              <td class="shadow-none lh-1 fw-medium text-paragraph">{{useCurrencify(invoice.total_ht)}}</td>
              <td class="shadow-none lh-1 fw-medium text-paragraph">
                {{useDateFormat(invoice.date)}}
              </td>
              <td class="shadow-none lh-1 fw-medium">
                <span class="badge text-outline-success" :class="{'text-outline-danger': invoice.statut != 2}">{{invoiceStatus(invoice.statut)}}</span>
              </td>
              <td
                class="shadow-none lh-1 fw-medium text-paragraph"
              >
              <div>
                  <a href="#" @click="documents(invoice.id)" class="dropdown-toggle lh-1 bg-transparent border-0 shadow-none p-0 transition">
                    <i class="flaticon-download"></i> 
                  </a>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
       <!-- Pagination Controls -->
       <div v-if="!loading && invoices.length && !searchQuery"  class="pagination-area d-md-flex mb-25 justify-content-between align-items-center">
        <p class="mb-0 text-paragraph">
          <!-- Showing <span class="fw-bold">{{ invoices.length * (page+1) }}</span> out of <span class="fw-bold">{{ totalInvoiceCount }}</span> results -->
        </p>
        <nav class="mt-15 mt-md-0">
          <ul class="pagination mb-0 d-flex flex-wrap">
            <li class="page-item">
              <!-- <button class="page-link" aria-label="Previous" @click="fetchInvoices(pageNumber)">
                <i class="flaticon-chevron-1"></i>
              </button> -->
            </li>
            <li class="page-item" v-for="(pageNumber, index) in pageCount" :key="index">
              <button class="page-link pointer" :class="pageNumber-1 == page ? 'active' : ''" @click.prevent="fetchInvoices(pageNumber)">{{ pageNumber }}</button>
            </li>
            <li class="page-item">
              <!-- <button class="page-link" aria-label="Next" @click="fetchInvoices(pageNumber)">
                <i class="flaticon-chevron"></i>
              </button> -->
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref } from "vue";
import { useCurrencify } from "@/composables/useCurrencify";
import {useDateFormat} from '@/composables/useDateFormat';
import { debounce } from "lodash";
import axios from "axios";
import { useStoreUser } from "@/stores/storeUser";

const storeUser = useStoreUser();
const loading = ref(false);
const invoices = ref([]);
const searchQuery = ref();
const totalInvoiceCount = ref();
const pageCount = ref();
const page = ref(0);
const pageLimit = ref(8);
const invoiceStatus = (statut) => statut == 1 ? 'Unpaid' : 'Paid';

const fetchInvoices = async function(pageNumber) {    
      const sqlFilter = searchQuery.value 
        ? `(t.ref:like:%${searchQuery.value}%)`
        : '';
      try {
        loading.value = true;
        const config = {
          method: 'get',
          url: `/invoices`,
          params: {
            sortfield: "t.rowid",
            sortorder: "ASC",
            thirdparty_ids: storeUser.user.soc_id,
            status: "paid|unpaid",
            sqlfilters: sqlFilter,
            limit: pageLimit.value,
            page: searchQuery.value ? undefined : (pageNumber ? pageNumber - 1 : page.value)
          }
        };
        const response = await axios(config);
        invoices.value = response.data.filter(invoice => invoice.statut == 1 || invoice.statut == 2);
        if(pageNumber) {
          page.value = pageNumber - 1;
        }
        // totalInvoiceCount.value = response.data.length;
        // pageCount.value = Math.ceil(totalInvoiceCount.value / pageLimit.value);
      } catch (error) {
        console.error(error);
      } finally {
        loading.value = false;
      }
    };

const fetchInvoicesForPagination = async function() {
      try {
        loading.value = true;
        const config = {
          method: 'get',
          url: `/invoices`,
          params: {
            sortfield: "t.rowid",
            sortorder: "ASC",
            thirdparty_ids: storeUser.user.soc_id,
            status: "paid|unpaid",
          }
        };
        const response = await axios(config);
        // invoices.value = response.data;
        totalInvoiceCount.value = response.data.length;
        pageCount.value = Math.floor(totalInvoiceCount.value / pageLimit.value);
      } catch (error) {
        console.error(error);
      } finally {
        loading.value = false;
      }
    };

    const deboutFetchInvoices = debounce(fetchInvoices, 500);

    const fetchInvoicesByRef = () => {
      deboutFetchInvoices();
    }

const documents = async (id) => {
  try {
    const { data } = await axios.get('/documents', {
      params: {
        modulepart: 'invoice',
        id,
      },
    });
    const share = data[0]?.share;

    if (share) {
      const link = document.createElement('a');
      link.href = `https://doli.gigan-products.com/document.php?hashp=${share}`;
      link.target = '_blank';
      link.rel = 'noopener noreferrer';
      link.click();
    } else {
      console.warn('No share link available');
    }
  } catch (error) {
    console.error('Error fetching document:', error);
  }
};

onMounted(() => {
  fetchInvoices();
  fetchInvoicesForPagination();
});
</script>

<style scoped>
.pointer {
  cursor: pointer;
}

.loading-spinner {
  width: 50px;
  height: 50px;
  border: 5px solid rgba(0, 0, 0, 0.1);
  border-top: 5px solid #3498db;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
</style>