import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "d-flex justify-content-center"
}
const _hoisted_2 = { key: 1 }

import { defineComponent, onMounted, ref } from "vue";

import LoginForm from "../../components/Authentication/Login/LoginForm.vue";
import { useStoreUser } from "@/stores/storeUser";
import { useRouter } from "vue-router";


export default /*@__PURE__*/_defineComponent({
  __name: 'LoginPage',
  setup(__props) {

const storeUser = useStoreUser();
const router = useRouter();
const isLoggedIn = ref(false)
const loading = ref(false);

onMounted(() => {
  loading.value = true;
  storeUser.isLoggedIn()
    .then((res) => {
      if (res) {
        isLoggedIn.value = true;
        router.push("/dashboard");
      }
    })
    .finally(() => {
      loading.value = false;
    });
});


defineComponent({
  name: "LoginPage",
  components: {
    LoginForm,
  },
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    (loading.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, _cache[0] || (_cache[0] = [
          _createElementVNode("div", {
            class: "spinner-border text-primary",
            role: "status"
          }, [
            _createElementVNode("span", { class: "visually-hidden" }, "Loading...")
          ], -1)
        ])))
      : (!isLoggedIn.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createVNode(LoginForm)
          ]))
        : _createCommentVNode("", true)
  ]))
}
}

})