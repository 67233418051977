<template>
  <div>
  <div v-if="loading" class="d-flex justify-content-center">
    <div class="spinner-border text-primary" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>
    <div v-else-if="!isLoggedIn">
        <LoginForm />  
    </div>
  </div>
</template>

<script setup lang="ts">
import { defineComponent, onMounted, ref } from "vue";

import LoginForm from "../../components/Authentication/Login/LoginForm.vue";
import { useStoreUser } from "@/stores/storeUser";
import { useRouter } from "vue-router";

const storeUser = useStoreUser();
const router = useRouter();
const isLoggedIn = ref(false)
const loading = ref(false);

onMounted(() => {
  loading.value = true;
  storeUser.isLoggedIn()
    .then((res) => {
      if (res) {
        isLoggedIn.value = true;
        router.push("/dashboard");
      }
    })
    .finally(() => {
      loading.value = false;
    });
});


defineComponent({
  name: "LoginPage",
  components: {
    LoginForm,
  },
});
</script>
