<template>
  <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing">
    <div
      class="card-head box-shadow bg-white d-md-flex align-items-center justify-content-between p-15 p-sm-20 p-md-25"
    >
      <form class="search-box position-relative">
        <input
          type="text"
          class="form-control shadow-none text-black rounded-0 border-0"
          placeholder="Search order"
           v-model="searchQuery"
          @keyup="fetchInvoicesByRef()"
        />
        <button
          type="submit"
          class="bg-transparent text-primary transition p-0 border-0"
        >
          <i class="flaticon-search-interface-symbol"></i>
        </button>
      </form>
      <div class="d-sm-flex align-items-center">
      </div>
    </div>
    <div class="card-body p-15 p-sm-20 p-md-25">
      <div v-if="loading" class="d-flex align-items-center justify-content-center h-100">
        <div class="loading-spinner"></div>
      </div>
      <div v-else-if="!orders.length" class="d-flex align-items-center justify-content-center h-100">
        <h2>No results Found</h2>
      </div>
      <div v-else class="table-responsive">
        <table class="table text-nowrap align-middle mb-0">
          <thead>
            <tr>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 ps-0"
              >
                Ref
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                Amount
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                Date
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                Order Status
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 ps-0"
              >
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(order, index) in orders" :key="index">
              <td class="shadow-none lh-1 fw-medium text-paragraph">
                {{ order.ref }}
              </td>
              <td class="shadow-none lh-1 fw-medium text-paragraph">{{ useCurrencify(order.total_ht) }}</td>
              <td class="shadow-none lh-1 fw-medium text-paragraph">
                {{useDateFormat(order.date)}}
              </td>
              <td class="shadow-none lh-1 fw-medium">
                <span class="badge" :class="{'text-outline-danger': order.statut == -1, 'text-outline-primary': order.statut == 0, 'text-outline-warning': order.statut == 1, 'text-outline-info': order.statut == 2, 'text-outline-success': order.statut == 3}">{{orderStatus(order.statut)}}</span>
              </td>
              <td
                class="shadow-none lh-1 fw-medium text-paragraph"
              >
              <div>
                  <router-link :to="`/order-details/${order.id}`"  class="dropdown-toggle lh-1 bg-transparent border-0 shadow-none p-0 transition">
                    <i class="flaticon-view"></i> 
                  </router-link>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
          <!-- Pagination Controls -->
          <div v-if="!loading && orders.length && !searchQuery"  class="pagination-area d-md-flex mb-25 justify-content-between align-items-center">
        <p class="mb-0 text-paragraph">
          <!-- Showing <span class="fw-bold">{{ invoices.length * (page+1) }}</span> out of <span class="fw-bold">{{ totalInvoiceCount }}</span> results -->
        </p>
        <nav class="mt-15 mt-md-0">
          <ul class="pagination mb-0 d-flex flex-wrap">
            <li class="page-item">
              <!-- <button class="page-link" aria-label="Previous" @click="fetchInvoices(pageNumber)">
                <i class="flaticon-chevron-1"></i>
              </button> -->
            </li>
            <li class="page-item" v-for="(pageNumber, index) in pageCount" :key="index">
              <button class="page-link pointer" :class="pageNumber-1 == page ? 'active' : ''" @click.prevent="fetchOrders(pageNumber)">{{ pageNumber }}</button>
            </li>
            <li class="page-item">
              <!-- <button class="page-link" aria-label="Next" @click="fetchInvoices(pageNumber)">
                <i class="flaticon-chevron"></i>
              </button> -->
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref } from "vue";
import { useCurrencify } from "@/composables/useCurrencify";
import {useDateFormat} from '@/composables/useDateFormat';
import { debounce } from "lodash";
import axios from "axios";
import { useStoreUser } from "@/stores/storeUser";

const storeUser = useStoreUser();
const loading = ref(false);
const orders = ref([]);
const searchQuery = ref();
const totalInvoiceCount = ref();
const pageCount = ref();
const page = ref(0);
const pageLimit = ref(10);
const orderStatus = (statut) => {
  switch (statut) {
    case '1':
      return 'Validated';
    case '2':
      return 'In Progress';
    case '3':
      return 'Shipped';
    case '0':
      return 'Draft';
    default:
      return 'Cancelled';
  }
};

// TODO: Need to adjust thirdparty id
const fetchOrders = async (pageNumber = null) => {  
    const sqlFilter = searchQuery.value 
        ? `(t.ref:like:%${searchQuery.value}%)`
        : '';
      try {
        loading.value = true;
        const config = {
          method: 'get',
          url: `/orders`,
          params: {
            sortfield: "t.rowid",
            sortorder: "DESC",
            thirdparty_ids: storeUser.user.soc_id,
            sqlfilters: sqlFilter,
            limit: pageLimit.value,
            page: searchQuery.value ? undefined : (pageNumber ? pageNumber - 1 : page.value)
          }
        };
        const response = await axios(config);
        orders.value = response.data
        
        if(pageNumber) {
          page.value = pageNumber - 1;
        }
      } catch (error) {
        console.error(error);
      } finally {
        loading.value = false;
      }
  }
  // TODO: Need to adjust thirdparty id
  const fetchOrdersForPagination = async function() {
  loading.value = true;
  try {
    const { data } = await axios.get('/orders', {
      params: {
        sortfield: "t.rowid",
        sortorder: "DESC",
        thirdparty_ids: storeUser.user.soc_id
      }
    });
    totalInvoiceCount.value = data.length;
    pageCount.value = Math.ceil(totalInvoiceCount.value / pageLimit.value);
  } catch (error) {
    console.error(error);
  } finally {
    loading.value = false;
  }
};

    const deboutFetchInvoices = debounce(fetchOrders, 500);

    const fetchInvoicesByRef = () => {
      deboutFetchInvoices();
    }


onMounted(() => {  
  fetchOrders();
  fetchOrdersForPagination();
});

</script>

<style scoped>
.pointer {
  cursor: pointer;
}

.loading-spinner {
  width: 50px;
  height: 50px;
  border: 5px solid rgba(0, 0, 0, 0.1);
  border-top: 5px solid #3498db;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
</style>