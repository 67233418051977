<template>
  <div class="row justify-content-center">
    <div class="col-md-10 col-lg-8 col-xl-9 col-xxl-8 col-xxxl-6">
      <div v-if="loading" class="d-flex align-items-center justify-content-center h-100">
        <div class="loading-spinner"></div>
      </div>
      <div v-else class="card mb-25 border-0 rounded-0 bg-white authentication-card">
        <div class="card-body letter-spacing">
          <h4 class="text-black fw-bold mb-0 text-center">
            Sign In To Your Account!
          </h4>
          <form @submit.prevent="submit">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Email Address
              </label>
              <input
                type="email"
                class="form-control shadow-none rounded-0 text-black"
                placeholder="john.doe@gmail.com"
                v-model="email"
              />
            </div>
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Password
              </label>
              <input
                type="password"
                class="form-control shadow-none rounded-0 text-black"
                placeholder="**************"
                v-model="password"
              />
            </div>
            <div
              class="d-flex align-items-center justify-content-between mb-15 mb-md-20"
            >
              <div
                class="form-check form-check-primary mb-0 fs-md-15 fs-lg-16 text-muted lh-1"
              >
                <input
                  class="form-check-input shadow-none"
                  type="checkbox"
                  id="remember-me"
                />
                <label class="form-check-label" for="remember-me">
                  Remember Me
                </label>
              </div>
            </div>
            <button
              class="default-btn transition border-0 fw-medium text-white rounded-1 fs-md-15 fs-lg-16 bg-success d-block w-100"
              type="submit"
            >
              Sign In Now
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import axios from "axios";
import { useStoreUser } from "@/stores/storeUser.js";
import { ref } from "vue";
import { useRouter } from "vue-router";
import { useNotification } from "@kyvg/vue3-notification";

const storeUser = useStoreUser();
const email = ref("");
const password = ref("");
const loading = ref(false);
const router = useRouter();
const { notify } = useNotification();

const submit = async () => {
  const config = {
    method: 'post',
    url: `/login`,
    baseURL: process.env.VUE_APP_LARAVEL_BASE_URL,
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    params: {
      email: email.value,
      password: password.value,
    }
  };

  try {
    loading.value = true;
    const response = await axios(config);
    await storeUser.setToken(response.data.token);
    await storeUser.attempt();
    storeUser.authenticated = true
    
    if(storeUser.getAuthenticated){
      router.push({name: 'LMSCoursesPage'});
    }
    notify({
        type: "success",
        text: "Login successful!",
      });
  } catch (error) {
    notify({
        type: "warn",
        text: "Credentials are incorrect!",
      });
  } finally {
    loading.value = false;
  }
};
</script>

<style scoped>
.pointer {
  cursor: pointer;
}

.loading-spinner {
  width: 50px;
  height: 50px;
  border: 5px solid rgba(0, 0, 0, 0.1);
  border-top: 5px solid #3498db;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
</style>