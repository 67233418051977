<template>
  <div class="row">
    <div class="col-lg-4 col-xxxl-3">
      <SideBar @search="fetchProductsByLabel" @categorySearch="fetchProductsByCategory" />
    </div>
    <div class="col-lg-8 col-xxxl-9">
      <!-- Loading Spinner -->
      <div v-if="loading" class="d-flex align-items-center justify-content-center h-100">
        <div class="loading-spinner"></div>
      </div>
      <div v-else-if="!products.length" class="d-flex align-items-center justify-content-center h-100">
        <h2>No results Found</h2>
      </div>
      <!-- Product List (shown when not loading) -->
      <div v-else class="row">
        <div class="col-sm-6 col-lg-6 col-xxxl-3" v-for="(product, index) in products" :key="index">
          <div
            class="card mb-25 border-0 rounded-0 bg-white single-product-box">
            <div class="card-body p-0 letter-spacing shopping-cart-box">
              <div class="image position-relative">
                <router-link class="d-block" :to="{ name: 'ProductDetailsPage', params: { id: product.id } }">
                  <img
                    :src="product.documents && product.documents.length > 0 ? product.documents[0].image : require('../../../assets/images/products/product1.jpg')"
                    alt="product"
                    style="width: 100%; height: 250px"
                  />
                </router-link>
              </div>
              <div class="content p-20">
                <h4 class="mb-10 fw-semibold fs-16 fs-lg-18">
                  <div>
                   Ref: {{ product.ref ? product.ref : 'N/A' }}
                  </div>
                  <router-link class="text-decoration-none text-black" :to="{ name: 'ProductDetailsPage', params: { id: product.id } }">
                    {{ product.label }}
                  </router-link>
                </h4>
                <div class="mt-10 price d-flex align-items-center">
                  <span class="text-primary fw-bold fs-md-15 fs-lg-16">{{ useCurrencify(product.price) && !isNaN(product.price) ? useCurrencify(product.price) : 'N/A' }}</span>
                </div>
                <div class="product-details-box">
                <div class="card-body product-details-box">
                  <div class="product-details-content">
                    <div class="buttons-list d-sm-flex align-items-center">
                      <div class="input-counter d-flex align-items-center">
                        <div class="number-counter me-15" id="number-counter">
                          <button type="button" @click="decreaseItemNumber(product.id)">
                            <i class="flaticon-minus"></i>
                          </button>
                          <input type="number" :value="product.itemNumber" readonly/>
                          <button type="button" @click="increaseItemNumber(product.id)">
                            <i class="flaticon-plus"></i>
                          </button>
                        </div>
                        Stock : {{ product.stock_reel }} | Qty minimal : {{  product.array_options?.options_qtymin }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
                <button
                  @click="addToCart(product)"
                  type="button"
                  class="add-to-cart-btn text-center d-block mt-15 fw-medium transition w-100 rounded-1 position-relative"
                  :class="{'bg-primary text-white': storeProduct.isAlreadyAdded(product.id)}"
                  >
                  {{ storeProduct.isAlreadyAdded(product.id) ? "Added" : "Add To Cart" }}
                  <i class="flaticon-shopping-cart-2 transition"></i>
                </button>
                <router-link class="d-block" :to="{ name: 'ProductDetailsPage', params: { id: product.id } }">
                <button
                  type="button"
                  class="add-to-cart-btn text-center d-block mt-15 fw-medium transition w-100 rounded-1 position-relative">
                  Details
                </button>
               </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Pagination Controls -->
      <div v-if="!loading && products.length && !paginate"  class="pagination-area d-md-flex mb-25 justify-content-between align-items-center">
        <p class="mb-0 text-paragraph">
          Showing <span class="fw-bold">{{ products.length * (page+1) }}</span> out of <span class="fw-bold">{{ totalProductCount }}</span> results
        </p>
        <nav class="mt-15 mt-md-0">
          <ul class="pagination mb-0 d-flex flex-wrap">
            <li class="page-item">
              <button class="page-link" aria-label="Previous" @click="pageUp('previous')">
                <i class="flaticon-chevron-1"></i>
              </button>
            </li>
            <li class="page-item" v-for="(pageNumber, index) in pageCount" :key="index">
              <button class="page-link pointer" :class="pageNumber-1 == page ? 'active' : ''" @click.prevent="pageUp(null, pageNumber)">{{ pageNumber }}</button>
            </li>
            <li class="page-item">
              <button class="page-link" aria-label="Next" @click="pageUp('next')">
                <i class="flaticon-chevron"></i>
              </button>
            </li>
          </ul>
        </nav>
      </div>

      
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, onUpdated } from "vue";
import SideBar from "./SideBar.vue";
import axios from "axios";
import { debounce } from "lodash";
import { useCurrencify } from "@/composables/useCurrencify";
import mitt from '@/EventBus';
import { useRoute } from "vue-router";

import { useStoreProduct } from "@/stores/storeProduct.js";
import { useNotification } from "@kyvg/vue3-notification";

const products = ref([]);
const paginate = ref(true);
const page = ref(0);
const pageCount = ref(0);
const searchQuery = ref();
const limit = ref(12);
const totalProductCount = ref(0);
const loading = ref(false);
const catSearch = ref([]);
const catalog = ref('');

const storeProduct = useStoreProduct();
const { notify } = useNotification();
const route = useRoute();

const increaseItemNumber =(productId) => {
  const product = products.value.find((item) => item.id === productId);
  if (Number(product.stock_reel) > Number(product.itemNumber) && (Number(product.itemNumber) + Number(product.array_options?.options_qtymin)) < Number(product.stock_reel)) {
      product.itemNumber = Number(product.itemNumber) + Number(product.array_options?.options_qtymin);
  }else{
    product.itemNumber = Number(product.stock_reel);
  }  
}

const decreaseItemNumber = (productId) => {
  const product = products.value.find((item) => item.id === productId);
  if (product && Number(product.itemNumber) > 0 && Number(product.itemNumber) > Number(product.array_options?.options_qtymin)) {
    if(Number(product.stock_reel) == Number(product.itemNumber)){
      let nearestInteger = Math.floor((Number(product.itemNumber) / Number(product.array_options?.options_qtymin)));
      product.itemNumber = nearestInteger * Number(product.array_options?.options_qtymin);
    }else{
      product.itemNumber = Number(product.itemNumber) - Number(product.array_options?.options_qtymin);
    }
  }
};

const fetchProductsByCategory = (cats) => {  
  catSearch.value = cats;
  page.value = 0;
  debouncedFetchProducts(searchQuery.value, cats);
}
const fetchProductsByLabel = (label) => {
  searchQuery.value = label;
  page.value = 0;
  debouncedFetchProducts(searchQuery.value, catSearch.value);
}

const fetchProducts = async (query, cats) => {
  try {
    loading.value = true;
    paginate.value = true;
    searchQuery.value = query;

    // Build the SQL filter based on the query
    const baseFilter = catalog.value ? `(ef.api_brand:=:${catalog.value}) and (t.tosell:=:1) and (t.stock:>:0)` : `(t.tosell:=:1) and (t.stock:>:0)`;
    const sqlFilter = searchQuery.value 
      ? `(t.label:like:%${searchQuery.value}%) and ${baseFilter}`
      : baseFilter;

    //const filter = `(t.tosell:=:1) and (t.stock:>:0) and (t.ref.api_brand:=:1`;

    const response = await axios.get('/products', {
      params: {
        sortfield: 't.ref',
        sortorder: 'ASC',
        sqlfilters: sqlFilter,
        page: page.value,
        pagination_data: true,
        limit: !cats || cats.length === 0 ? limit.value : '',
        mode: 1,
      },
    });

    const { data, pagination } = response.data;

    // If no categories are provided, show all products
    if (!cats || cats.length === 0) {
      paginate.value = false;
      products.value = data.map(product => ({
        ...product,
        itemNumber: product.array_options?.options_qtymin,
      }));
      pageCount.value = pagination.page_count;
      totalProductCount.value = pagination.total;
    } else {
      // Filter products based on category matches
      const filteredProducts = data.filter(product => {
        const categories = product.array_options?.options_cat_api_b2b?.split(',') || [];
        return cats.some(target => categories.includes(target));
      });

      // Update the product list if any filtered products exist
      products.value = filteredProducts.length > 0 
        ? filteredProducts.map(product => ({
            ...product,
            itemNumber: product.array_options?.options_qtymin,
          }))
        : [];
      
      console.log(products.value);
      
    }
  } catch (error) {
    console.error('Error fetching data:', error);
  } finally {
    loading.value = false;
  }
};


const debouncedFetchProducts = debounce(fetchProducts, 500);

const pageUp = (action = null, pageNumber = null) => {
  if (pageNumber !== null) {
    page.value = pageNumber - 1;
    debouncedFetchProducts(searchQuery.value);
  } else if (action === 'next' && page.value < pageCount.value - 1) {
    page.value++;    
    debouncedFetchProducts(searchQuery.value);
  } else if (action === 'previous' && page.value > 0) {
    page.value--;
    debouncedFetchProducts(searchQuery.value);
  }
};


const addToCart = async (product) => {  
    if (product.stock_reel <= 0) {
      notify({
        type: "warn",
        text: "Product is out of stock!",
      });
      return;
    }
    if (storeProduct.isAlreadyAdded(product.id)) {
      notify({
        type: "warn",
        text: "Product already added to cart!",
      });
    return;
  }
  storeProduct.addItem({
    ...product,
    totalPrice: product.itemNumber * product.price,
  });
  notify({
    type: "success",
    text: "Product added to cart successfully!",
  });
}

const fetchDocumentsForAllProducts = async () => {
  try {
    for (const product of products.value) {
      try {
        const { data } = await axios.get('/documents', {
          params: {
            modulepart: 'produit',
            id: product.id,
          },
        });

        if (!data || data.length === 0) {
          continue;
        }

        const documentsData = data.map(document => ({
          docid: document.id,
          filepath: document.filepath,
          filename: document.filename,
          image: `https://doli.gigan-products.com/documents/${document.filepath}/${document.filename}`,
        }));

        if (!product.documents) {
          product.documents = [];
        }

        product.documents.push(...documentsData);
      } catch (innerError) {
        continue;
      }
    }
  } catch (error) {
    console.error('Unexpected error while fetching documents:', error);
  }
};




onMounted(async() => {
  const id = route.params.id;
  
  if (id) {
    catalog.value = id;
  }
  
  const emitter = mitt;
  emitter.on('fetchByCatalog', function(id){    
    catalog.value = id;
    fetchProducts();
  });
  emitter.on('loadProducts', function(){    
    catalog.value = ''; 
    fetchProducts();
  });
  await fetchProducts();
  await fetchDocumentsForAllProducts();
});


onUpdated(() => {
  fetchDocumentsForAllProducts();
})
</script>



<style scoped>
.pointer {
  cursor: pointer;
}

.loading-spinner {
  width: 50px;
  height: 50px;
  border: 5px solid rgba(0, 0, 0, 0.1);
  border-top: 5px solid #3498db;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
</style>
