import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, unref as _unref, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass } from "vue"
import _imports_0 from '../../../assets/images/welcome/welcome3.png'


const _hoisted_1 = {
  key: 0,
  class: "d-flex justify-content-center"
}
const _hoisted_2 = {
  key: 1,
  class: "card mb-25 border-0 rounded-0 welcome-lms-courses-box"
}
const _hoisted_3 = { class: "card-body ps-15 pe-15 ps-sm-20 pe-sm-20 ps-md-25 pe-md-25 ps-lg-30 pe-lg-30 ps-xl-40 pe-xl-40 letter-spacing" }
const _hoisted_4 = { class: "row align-items-center" }
const _hoisted_5 = { class: "col-xxl-6" }
const _hoisted_6 = { class: "content" }
const _hoisted_7 = {
  class: "d-block fw-bold mb-5",
  id: "current-date"
}
const _hoisted_8 = { class: "fw-semibold mb-8 text-white" }
const _hoisted_9 = { class: "fw-bold text-white" }
const _hoisted_10 = { class: "row list justify-content-center" }
const _hoisted_11 = { class: "d-block mb-6 fw-medium text-black-emphasis fs-13 text-uppercase" }

import { ref, onMounted } from "vue";
import { useStoreUser } from "@/stores/storeUser";
import axios from "axios";


export default /*@__PURE__*/_defineComponent({
  __name: 'WhatHappening',
  setup(__props) {

const storeUser = useStoreUser();
const loading = ref(false);
const updates = ref([
  { id: 1, bgClass: "bg-f2f1f9", title: "Orders", class: "text-primary", number: '-' },
  { id: 2, bgClass: "bg-faf7f7", title: "Invoices", class: "text-danger", number: "-" },
  { id: 3, bgClass: "bg-ecf3f2", title: "-", class: "text-success", number: "-" },
  { id: 4, bgClass: "bg-f3f7f9", title: "-", class: "text-info", number: "-" },
]);

const formattedDate = ref("");

const fetchData = async (url: string, index: number) => {
  loading.value = true;
  try {
    const config = {
      method: 'get',
      url,
      params: {
        sortfield: "t.rowid",
        sortorder: "ASC",
        thirdparty_ids: storeUser.user.soc_id,
      }
    };
    const response = await axios(config);
    updates.value[index].number = response.data.length;
  } catch (error) {
    console.error(error);
  } finally {
    loading.value = false;
  }
}

onMounted(() => {
  fetchData('/orders', 0);
  fetchData('/invoices', 1);

  const months = [
    "January", "February", "March", "April", "May", "June", "July",
    "August", "September", "October", "November", "December"
  ];

  const currentDate = new Date();
  formattedDate.value = ` ${currentDate.getDate()} ${months[currentDate.getMonth()]}, ${currentDate.getFullYear()}`;
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    (loading.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, _cache[0] || (_cache[0] = [
          _createElementVNode("div", {
            class: "spinner-border text-primary",
            role: "status"
          }, [
            _createElementVNode("span", { class: "visually-hidden" }, "Loading...")
          ], -1)
        ])))
      : (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("div", _hoisted_6, [
                  _createElementVNode("span", _hoisted_7, _toDisplayString(formattedDate.value), 1),
                  _createElementVNode("h2", _hoisted_8, [
                    _cache[1] || (_cache[1] = _createTextVNode(" Welcome Back, ")),
                    _createElementVNode("span", _hoisted_9, _toDisplayString(_unref(storeUser).user.name), 1)
                  ]),
                  _createElementVNode("div", _hoisted_10, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(updates.value, (update) => {
                      return (_openBlock(), _createElementBlock("div", {
                        class: "col-lg-3 col-6 col-sm-3",
                        key: update.id
                      }, [
                        _createElementVNode("div", {
                          class: _normalizeClass(['p-15 mt-15', update.bgClass])
                        }, [
                          _createElementVNode("span", _hoisted_11, _toDisplayString(update.title), 1),
                          _createElementVNode("h4", {
                            class: _normalizeClass(['mb-0 fw-black', update.class])
                          }, _toDisplayString(update.number), 3)
                        ], 2)
                      ]))
                    }), 128))
                  ])
                ])
              ]),
              _cache[2] || (_cache[2] = _createElementVNode("div", { class: "col-xxl-6 text-center text-xxl-end mt-15 mt-md-0" }, [
                _createElementVNode("img", {
                  src: _imports_0,
                  class: "main-image",
                  alt: "welcome-image"
                })
              ], -1))
            ])
          ])
        ]))
  ]))
}
}

})