import { defineStore } from 'pinia'
import { ref, computed, watch } from 'vue'

export const useStoreProduct = defineStore('product', () => {
    //state
    const cartItems = ref(JSON.parse(localStorage.getItem('cartItems')) || []);
    
  
    //getters
    const totalCartItem = computed(() => {
        return cartItems.value.length
      })

    //actions
    const addItem = (addCartItem) => {
        const newEntry = Object.assign({}, addCartItem)
        cartItems.value.push(newEntry)
    }

    const deleteFromCart = (deleteId) => {
        const index = cartItems.value.findIndex(item => item.id === deleteId)
        if (index > -1) {
            cartItems.value.splice(index, 1)
        }
    }

    const isAlreadyAdded = (id) => {
        return cartItems.value.some(item => item.id === id)
    }

    const increaseItemNumberById = (productId) => {
        const product = cartItems.value.find((item) => item.id === productId);
        if (Number(product.stock_reel) > Number(product.itemNumber) && (Number(product.itemNumber) + Number(product.array_options?.options_qtymin)) < Number(product.stock_reel)) {
            product.itemNumber = Number(product.itemNumber) + Number(product.array_options?.options_qtymin);
        }else{
          product.itemNumber = Number(product.stock_reel);
        }  
    }

    const decreaseItemNumberById = (productId) => {
      const product = cartItems.value.find((item) => item.id === productId);
      if (product && Number(product.itemNumber) > 0 && Number(product.itemNumber) > Number(product.array_options?.options_qtymin)) {
        if(Number(product.stock_reel) == Number(product.itemNumber)){
          let nearestInteger = Math.floor((Number(product.itemNumber) / Number(product.array_options?.options_qtymin)));
          product.itemNumber = nearestInteger * Number(product.array_options?.options_qtymin);
        }else{
          product.itemNumber = Number(product.itemNumber) - Number(product.array_options?.options_qtymin);
        }
      }
    }
    
    const clearCart = () => {
        cartItems.value = []
        localStorage.removeItem('cartItems')
    }

    //watch
    watch(cartItems, (newCartItems) => {
      newCartItems.forEach(product => {
        product.totalPrice = product.price * product.itemNumber;
      });
    
      localStorage.setItem('cartItems', JSON.stringify(newCartItems));
    }, { deep: true });

    //computed
    const grossTotal = computed(() => {
        return cartItems.value.reduce((accumulator, { totalPrice }) => {
          return accumulator + totalPrice
        }, 0)
      })
    

    //return
    return { cartItems, addItem, totalCartItem, deleteFromCart, clearCart, isAlreadyAdded, increaseItemNumberById, decreaseItemNumberById, grossTotal }
  })