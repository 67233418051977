<template>
  <div>
  <WhatHappening />

  <div class="row">
    <div class="col-xxl-6">
      <div class="row">
        <div class="col-lg-6">
          <TodaysCourse />
        </div>
        <div class="col-lg-6">
          <TodaysEvent />
        </div>
      </div>
    </div>
    <div class="col-xxl-6">
      <AverageEnrollmentRate />
    </div>
    <div class="col-xxl-4">
      <CategoriesCourses />

      <StudentsProgress />
    </div>
    <div class="col-xxl-8">
      <CoursesClass />
      <FundamentalCourses />
      <div class="row">
        <div class="col-lg-6 col-xxxl-5">
          <PerformanceProgress />
        </div>
        <div class="col-lg-6 col-xxxl-7">
          <ToDoList />
        </div>
      </div>
    </div>
  </div>
  </div>
</template>

<script>
import { defineComponent, onMounted } from "vue";
import { useStoreUser } from "@/stores/storeUser";
import { useRouter } from "vue-router";
import WhatHappening from "../../components/Dashboard/Home/WhatHappening.vue";

export default defineComponent({
  name: "DashboardPage",
  components: {
    WhatHappening,
  },
  setup() {
    const storeUser = useStoreUser();
    const router = useRouter();

    onMounted(() => {
      if (!storeUser.authenticated) {
        router.push("/login");
      }
    });

    return {};
  },
});
</script>
