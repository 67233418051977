<template>
  <div>
    <MainHeader :user="storeUser" v-show="storeUser.authenticated"/>
    <MainSidebar v-show="storeUser.authenticated" />
    <div class="main-content d-flex flex-column transition overflow-hidden">
      <router-view v-slot="{Component}">
        <transition name="fade" mode="out-in">
          <component :is="Component" :key="$route.path"></component>
        </transition> 
      </router-view>
      <MainFooter v-if="storeUser.authenticated" />
    </div>
    <notifications position="top right" width="400"/>
  </div>
</template>

<script lang="ts">
import { defineComponent, watchEffect, onMounted, ref } from "vue";
import stateStore from "./utils/store";

import MainHeader from "./components/Layouts/MainHeader.vue";
import MainSidebar from "./components/Layouts/MainSidebar.vue";
import MainFooter from "./components/Layouts/MainFooter.vue";
import { useStoreUser } from "./stores/storeUser";

export default defineComponent({
  name: "App",
  components: {
    MainHeader,
    MainSidebar,
    MainFooter,
  },
  mounted() {
    document.body.classList.add("bg-body-secondary");
  },
  setup() {
    const storeUser = useStoreUser();

    const stateStoreInstance = stateStore;
    watchEffect(() => {
      if (stateStoreInstance.open) {
        document.body.classList.remove("sidebar-show");
        document.body.classList.add("sidebar-hide");
      } else {
        document.body.classList.remove("sidebar-hide");
        document.body.classList.add("sidebar-show");
      }
    });
    return {
      storeUser,
    };
  },
});
</script>

<style lang="css">
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
