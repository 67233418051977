<template>
  <div>
    <div v-if="loading" class="d-flex justify-content-center">
      <div class="spinner-border text-primary" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
    <div v-else class="card mb-25 border-0 rounded-0 welcome-lms-courses-box">
      <div
        class="card-body ps-15 pe-15 ps-sm-20 pe-sm-20 ps-md-25 pe-md-25 ps-lg-30 pe-lg-30 ps-xl-40 pe-xl-40 letter-spacing"
      >
        <div class="row align-items-center">
          <div class="col-xxl-6">
            <div class="content">
              <span class="d-block fw-bold mb-5" id="current-date">
                {{ formattedDate }}
              </span>
              <h2 class="fw-semibold mb-8 text-white">
                Welcome Back,
                <span class="fw-bold text-white">{{ storeUser.user.name }}</span>
              </h2>
              <div class="row list justify-content-center">
                <div
                  class="col-lg-3 col-6 col-sm-3"
                  v-for="update in updates"
                  :key="update.id"
                >
                  <div :class="['p-15 mt-15', update.bgClass]">
                    <span
                      class="d-block mb-6 fw-medium text-black-emphasis fs-13 text-uppercase"
                    >
                      {{ update.title }}
                    </span>
                    <h4 :class="['mb-0 fw-black', update.class]">
                      {{ update.number }}
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xxl-6 text-center text-xxl-end mt-15 mt-md-0">
            <img
              src="../../../assets/images/welcome/welcome3.png"
              class="main-image"
              alt="welcome-image"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from "vue";
import { useStoreUser } from "@/stores/storeUser";
import axios from "axios";

const storeUser = useStoreUser();
const loading = ref(false);
const updates = ref([
  { id: 1, bgClass: "bg-f2f1f9", title: "Orders", class: "text-primary", number: '-' },
  { id: 2, bgClass: "bg-faf7f7", title: "Invoices", class: "text-danger", number: "-" },
  { id: 3, bgClass: "bg-ecf3f2", title: "-", class: "text-success", number: "-" },
  { id: 4, bgClass: "bg-f3f7f9", title: "-", class: "text-info", number: "-" },
]);

const formattedDate = ref("");

const fetchData = async (url: string, index: number) => {
  loading.value = true;
  try {
    const config = {
      method: 'get',
      url,
      params: {
        sortfield: "t.rowid",
        sortorder: "ASC",
        thirdparty_ids: storeUser.user.soc_id,
      }
    };
    const response = await axios(config);
    updates.value[index].number = response.data.length;
  } catch (error) {
    console.error(error);
  } finally {
    loading.value = false;
  }
}

onMounted(() => {
  fetchData('/orders', 0);
  fetchData('/invoices', 1);

  const months = [
    "January", "February", "March", "April", "May", "June", "July",
    "August", "September", "October", "November", "December"
  ];

  const currentDate = new Date();
  formattedDate.value = ` ${currentDate.getDate()} ${months[currentDate.getMonth()]}, ${currentDate.getFullYear()}`;
});
</script>