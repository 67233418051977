<template>
  <div>
    <BreadCrumb PageTitle="Orders List" />
    <OrdersList />
  </div>
</template>

<script setup>
import { defineComponent, onMounted } from "vue";

import BreadCrumb from "../../components/Common/BreadCrumb.vue";
import OrdersList from "../../components/Ecommerce/OrdersList/OrdersList.vue";
import { useStoreUser } from "@/stores/storeUser";
import stateStore from "../../utils/store";
import { useRouter } from "vue-router";

const stateStoreInstance = stateStore;

const storeUser = useStoreUser();

const router = useRouter();

onMounted(() => {
  if (!storeUser.authenticated) {
    router.push("/login");
  }
  stateStoreInstance.catalog = "";
});


defineComponent({
  name: "OrdersListPage",
  components: {
    BreadCrumb,
    OrdersList,
  },
});
</script>