<template>
  <div>
    <BreadCrumb PageTitle="Order Details" />
    <OrderDetails />
  </div>
</template>

<script setup>
import { defineComponent } from "vue";
import BreadCrumb from "../../components/Common/BreadCrumb.vue";
import OrderDetails from "../../components/Ecommerce/OrderDetails/OrderDetails.vue";

defineComponent({
  name: "OrderDetailsPage",
  components: {
    BreadCrumb,
    OrderDetails,
  },
});
</script>
