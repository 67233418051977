export function useCurrencify(amount) {
  const currencySymbol = "€";
  const amountRounded = Math.round(amount * 100) / 100; // Arrondi correct à 2 décimales

  const amountFormatted = amountRounded.toLocaleString("fr-FR", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  return ` ${amountFormatted} ${currencySymbol}`;
}
