import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "footer-area text-center text-md-start bg-white ps-15 pe-15 pt-20 pb-20 ps-md-40 pe-md-40 pt-md-25 pb-md-25" }
const _hoisted_2 = { class: "row align-items-center" }
const _hoisted_3 = { class: "col-md-6 col-lg-6 text-md-end" }
const _hoisted_4 = { class: "footer-menu ps-0 mb-0 list-unstyled fw-medium" }
const _hoisted_5 = { class: "d-inline-block" }
const _hoisted_6 = { class: "d-inline-block" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _cache[3] || (_cache[3] = _createElementVNode("div", { class: "flex-grow-1" }, null, -1)),
    _createElementVNode("footer", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _cache[2] || (_cache[2] = _createElementVNode("div", { class: "col-md-6 col-lg-6" }, [
          _createElementVNode("p", { class: "text-body-secondary fw-medium mb-0" }, [
            _createTextVNode(" © "),
            _createElementVNode("span", { class: "fw-bold text-primary" }, "FD"),
            _createTextVNode(". All rights reserved ")
          ])
        ], -1)),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("ul", _hoisted_4, [
            _createElementVNode("li", _hoisted_5, [
              _createVNode(_component_router_link, {
                to: "/privacy-policy",
                class: "text-decoration-none text-body-secondary transition"
              }, {
                default: _withCtx(() => _cache[0] || (_cache[0] = [
                  _createTextVNode(" Privacy Policy ")
                ])),
                _: 1
              })
            ]),
            _createElementVNode("li", _hoisted_6, [
              _createVNode(_component_router_link, {
                to: "/terms-conditions",
                class: "text-decoration-none text-body-secondary transition"
              }, {
                default: _withCtx(() => _cache[1] || (_cache[1] = [
                  _createTextVNode(" Terms & Conditions ")
                ])),
                _: 1
              })
            ])
          ])
        ])
      ])
    ])
  ], 64))
}