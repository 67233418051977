import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { createBootstrap } from "bootstrap-vue-next";
import VueApexCharts from "vue3-apexcharts";
import { QuillEditor } from "@vueup/vue-quill";
import Vue3Prism from "vue3-prism/lib/Vue3Prism.common.js";
import { createPinia } from 'pinia'
import Notifications from '@kyvg/vue3-notification'

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue-next/dist/bootstrap-vue-next.css";
import "swiper/css";
import "swiper/css/bundle";
import "flatpickr/dist/flatpickr.css";
import "@vueup/vue-quill/dist/vue-quill.snow.css";
import "@vueup/vue-quill/dist/vue-quill.bubble.css";
import "vue3-prism/lib/Vue3Prism.css";

import "./assets/custom.scss";
import axios from 'axios';

axios.defaults.baseURL = process.env.VUE_APP_DOLIBARR_BASE_URL;
axios.defaults.headers.common['Accept'] = 'application/json';
axios.defaults.headers.common['DOLAPIKEY'] = '8W5rKRgvqwHx11Ylx73FUf60VmT5GXo5';
axios.defaults.headers.common['Content-Type'] = 'application/json';

const pinia = createPinia()

const app = createApp(App).use(Vue3Prism).use(router);
app.use(VueApexCharts);
app.use(createBootstrap());
app.use(pinia)
app.use(Notifications)
app.component("QuillEditor", QuillEditor);
app.mount("#app");
