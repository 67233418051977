import { resolveComponent as _resolveComponent, vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, Transition as _Transition, withCtx as _withCtx, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "main-content d-flex flex-column transition overflow-hidden" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MainHeader = _resolveComponent("MainHeader")!
  const _component_MainSidebar = _resolveComponent("MainSidebar")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_MainFooter = _resolveComponent("MainFooter")!
  const _component_notifications = _resolveComponent("notifications")!

  return (_openBlock(), _createElementBlock("div", null, [
    _withDirectives(_createVNode(_component_MainHeader, { user: _ctx.storeUser }, null, 8, ["user"]), [
      [_vShow, _ctx.storeUser.authenticated]
    ]),
    _withDirectives(_createVNode(_component_MainSidebar, null, null, 512), [
      [_vShow, _ctx.storeUser.authenticated]
    ]),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_router_view, null, {
        default: _withCtx(({Component}) => [
          _createVNode(_Transition, {
            name: "fade",
            mode: "out-in"
          }, {
            default: _withCtx(() => [
              (_openBlock(), _createBlock(_resolveDynamicComponent(Component), {
                key: _ctx.$route.path
              }))
            ]),
            _: 2
          }, 1024)
        ]),
        _: 1
      }),
      (_ctx.storeUser.authenticated)
        ? (_openBlock(), _createBlock(_component_MainFooter, { key: 0 }))
        : _createCommentVNode("", true)
    ]),
    _createVNode(_component_notifications, {
      position: "top right",
      width: "400"
    })
  ]))
}