import { createWebHistory, createRouter, RouteRecordRaw } from "vue-router";
import Home from "../pages/Dashboard/dashboard.vue";
import ProductsGridPage from "../pages/Ecommerce/ProductsGridPage.vue";
import ProductDetailsPage from "../pages/Ecommerce/ProductDetailsPage.vue";
import OrdersListPage from "../pages/Ecommerce/OrdersListPage.vue";
import OrderDetailsPage from "../pages/Ecommerce/OrderDetailsPage.vue";
import ShoppingCartPage from "../pages/Ecommerce/ShoppingCartPage.vue";
import InvoiceListPage from "../pages/Ecommerce/InvoiceListPage.vue";
import LoginPage from "../pages/Authentication/LoginPage.vue";
import UsersListPage from "../pages/Users/UsersListPage.vue";
import SwiperSliderPage from "../pages/Miscellaneous/SwiperSliderPage.vue";
import PrivacyPolicyPage from "../pages/Miscellaneous/PrivacyPolicyPage.vue";
import TermsConditionsPage from "../pages/Miscellaneous/TermsConditionsPage.vue";
import GalleryPage from "../pages/PagesInner/GalleryPage.vue";
import MaintenancePage from "../pages/PagesInner/MaintenancePage.vue";
import NotificationsPage from "../pages/PagesInner/NotificationsPage.vue";
import ConnectedAccountsPage from "../pages/PagesInner/ConnectedAccountsPage.vue";
import ProfilePage from "../pages/PagesInner/ProfilePage.vue";
import SearchResultPage from "../pages/PagesInner/SearchResultPage.vue";
import AccountSettingsPage from "../pages/Settings/AccountSettingsPage.vue";
import SettingsPrivacyPolicyPage from "../pages/Settings/SettingsPrivacyPolicyPage.vue";
import BlankPage from "../pages/BlankPage.vue";
import LogoutPage from "../pages/LogoutPage.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/dashboard",
    name: "LMSCoursesPage",
    component: Home,
  },
  {
    path: "/products/:id",
    name: "ProductsGridPage",
    component: ProductsGridPage,
  },

  {
    path: "/product-details/:id",
    name: "ProductDetailsPage",
    component: ProductDetailsPage,
  },
  {
    path: "/orders",
    name: "OrdersListPage",
    component: OrdersListPage,
  },
  {
    path: "/order-details/:id",
    name: "OrderDetailsPage",
    component: OrderDetailsPage,
  },
  {
    path: "/cart",
    name: "ShoppingCartPage",
    component: ShoppingCartPage,
  },
  {
    path: "/invoice",
    name: "InvoiceListPage",
    component: InvoiceListPage,
  },
  {
    path: "/login",
    name: "LoginPage",
    component: LoginPage,
  },
  {
    path: "/users-list",
    name: "UsersListPage",
    component: UsersListPage,
  },

  {
    path: "/ui-swiper-slider",
    name: "SwiperSliderPage",
    component: SwiperSliderPage,
  },
  {
    path: "/privacy-policy",
    name: "PrivacyPolicyPage",
    component: PrivacyPolicyPage,
  },
  {
    path: "/terms-conditions",
    name: "TermsConditionsPage",
    component: TermsConditionsPage,
  },


  { 
    // path: "/:pathMatch(.*)*", name: "ErrorPage", component: ErrorPage 
    path: "/",
    name: "LoginPage",
    component: LoginPage,
  },

  {
    path: "/gallery",
    name: "GalleryPage",
    component: GalleryPage,
  },
  {
    path: "/maintenance",
    name: "MaintenancePage",
    component: MaintenancePage,
  },
  {
    path: "/notifications",
    name: "NotificationsPage",
    component: NotificationsPage,
  },
  {
    path: "/connected-accounts",
    name: "ConnectedAccountsPage",
    component: ConnectedAccountsPage,
  },
  {
    path: "/profile",
    name: "ProfilePage",
    component: ProfilePage,
  },
  {
    path: "/search-result",
    name: "SearchResultPage",
    component: SearchResultPage,
  },
  {
    path: "/account-settings",
    name: "AccountSettingsPage",
    component: AccountSettingsPage,
  },
  {
    path: "/settings-privacy-policy",
    name: "SettingsPrivacyPolicyPage",
    component: SettingsPrivacyPolicyPage,
  },
  {
    path: "/blank-page",
    name: "BlankPage",
    component: BlankPage,
  },
  {
    path: "/logout",
    name: "LogoutPage",
    component: LogoutPage,
  },
];

const router = createRouter({
  history: createWebHistory(),
  linkExactActiveClass: "active",
  routes: routes,
  scrollBehavior() {
    return { top: 0, behavior: "smooth" };
  },
});

export default router;
