<template>
  <div
    :class="[
      'sidebar-area position-fixed start-0 top-0 bg-black h-100vh transition',
      { active: stateStoreInstance.open },
    ]"
    id="sidebar-area"
  >
    <!-- Logo Section -->
    <div class="logo position-absolute start-0 end-0 top-0 bg-black">
      <router-link
        to="/dashboard"
        class="d-flex align-items-center text-white text-decoration-none"
      >
        <img src="../../assets/images/favicon.png" alt="logo-icon" />
        <span class="fw-bold ms-10">Giganproducts</span>
      </router-link>
      <div class="border-bottom"></div>
      <button
        class="sidebar-burger-menu position-absolute lh-1 bg-transparent p-0 border-0"
        @click="stateStoreInstance.onChange"
      >
        <i class="ph-duotone ph-caret-double-right"></i>
      </button>
    </div>

    <!-- Sidebar Menu -->
    <div class="sidebar-menu">
      <ul class="sidebar-navbar-nav ps-0 mb-0 list-unstyled accordion" id="sidebarNavAccordion">
        <li class="sidebar-nav-item">
          <router-link to="/dashboard" class="sidebar-nav-link d-block">
            <i class="flaticon-more-1"></i>
            <span class="title">Dashboard</span>
          </router-link>
        </li>
        <!-- Dynamic Catalog Items -->
        <li v-for="catalog in catalogs" :key="catalog.id" class="sidebar-nav-item">
          <router-link
            :to="{ name: 'ProductsGridPage', params: { id: catalog.id } }"
            @click.prevent="fetchByCatalog(catalog.id)"
            class="sidebar-nav-link d-block"
          >
            <i class="flaticon-more-1"></i>
            <span class="title">{{ catalog.name }}</span>
          </router-link>
        </li>

        <!-- Static Sidebar Items -->
        <li class="sidebar-nav-item">
          <router-link to="/orders" class="sidebar-nav-link d-block">
            <i class="flaticon-more-1"></i>
            <span class="title">Orders</span>
          </router-link>
        </li>
        <li class="sidebar-nav-item">
          <router-link to="/invoice" class="sidebar-nav-link d-block">
            <i class="flaticon-more-1"></i>
            <span class="title">Invoices</span>
          </router-link>
        </li>
        <li class="sidebar-nav-item">
          <a @click.prevent="logout" class="sidebar-nav-link d-block">
            <i class="flaticon-logout"></i>
            <span class="title">Logout</span>
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>


<script setup>
import stateStore from "../../utils/store";
import { onMounted, ref } from "vue";
import mitt from '@/EventBus';
import router from "@/router";
const emitter = mitt;
import { useStoreUser } from "@/stores/storeUser";
import { useRoute } from 'vue-router'

const route = useRoute()

const storeUser = useStoreUser();

const catalogs = ref([
  { id: 1, name: "GIGANTERRA" },
  { id: 2, name: "GIGANAQUA" },
  { id: 3, name: "GIGABIRD " },
  { id: 4, name: "GIGAMOUSE " },
  { id: 5, name: "GIGANGARDEN&DECO" },
]);

const fetchByCatalog = (id) => {
  emitter.emit('fetchByCatalog', id);
  //router.push(`/products/${id}`);
};

const logout = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('user');
  storeUser.user = {};
  storeUser.token = '';
  storeUser.authenticated = false;
  router.push({name: 'LoginPage'});
};

onMounted(() => {
  console.log(route.params.id);
});


const stateStoreInstance = stateStore;
</script>
