<template>
  <div>
  <BreadCrumb PageTitle="Product Details" />
  <ProductDetails :product="product" v-on:itemNumber="incrementItemNumber" v-on:decrementItemNumber="decrementItemNumber" />
  </div>
</template>

<script>
import { defineComponent } from "vue";

import BreadCrumb from "../../components/Common/BreadCrumb.vue";
import ProductDetails from "../../components/Ecommerce/ProductDetails/ProductDetails.vue";
import axios from "axios";

export default defineComponent({
  name: "ProductDetailsPage",
  data(){
    return {
      product: [],
    }
  },
  mounted(){
    this.fetchProduct(this.$route.params.id);
  },
  methods:{
    async fetchProduct(productId) {
      try {
        const response = await axios.get('/products/' + productId, {
          params: {
            sortfield: 't.ref',
          },
        });
        const { data } = response;
        data.itemNumber = data.array_options?.options_qtymin;
        this.product = data;
                
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    },

    incrementItemNumber(){
      const product = this.product;
      if (Number(product.stock_reel) > Number(product.itemNumber) && (Number(product.itemNumber) + Number(product.array_options?.options_qtymin)) < Number(product.stock_reel)) {
      product.itemNumber = Number(product.itemNumber) + Number(product.array_options?.options_qtymin);
      }else{
        product.itemNumber = Number(product.stock_reel);
      }  
    },
    decrementItemNumber(){
      const product = this.product; 
      if (product && Number(product.itemNumber) > 0 && Number(product.itemNumber) > Number(product.array_options?.options_qtymin)) {
        if(Number(product.stock_reel) == Number(product.itemNumber)){
          let nearestInteger = Math.floor((Number(product.itemNumber) / Number(product.array_options?.options_qtymin)));
          product.itemNumber = nearestInteger * Number(product.array_options?.options_qtymin);
        }else{
          product.itemNumber = Number(product.itemNumber) - Number(product.array_options?.options_qtymin);
        }
      }
    }

  },
  components: {
    BreadCrumb,
    ProductDetails,
  },
});
</script>