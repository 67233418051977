<template>
  <div>
    <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing">
      <div
        class="card-head box-shadow bg-white d-lg-flex align-items-center justify-content-between p-15 p-sm-20 p-md-25"
      >
        <div class="d-sm-flex align-items-center">
          <a
            class="default-btn position-relative transition border-0 fw-medium text-white pt-11 pb-11 ps-25 pe-25 pt-md-12 pb-md-12 ps-md-30 pe-md-30 rounded-1 bg-success fs-md-15 fs-lg-16 d-inline-block me-10 mb-10 mb-lg-0 text-decoration-none"
            href="#"
            data-bs-toggle="modal"
            data-bs-target="#createNewUserModal"
          >
            Add New User
            <i class="flaticon-plus position-relative ms-5 fs-12"></i>
          </a>
          <button
            class="default-outline-btn position-relative transition fw-medium text-black pt-10 pb-10 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-30 pe-md-30 rounded-1 bg-transparent fs-md-15 fs-lg-16 d-inline-block mb-10 mb-lg-0"
            type="button"
          >
            Export
            <i class="flaticon-file-1 position-relative ms-5 top-2 fs-15"></i>
          </button>
        </div>
        <div class="d-flex align-items-center">
          <form class="search-box position-relative me-15">
            <input
              type="text"
              class="form-control shadow-none text-black rounded-0 border-0"
              placeholder="Search user"
            />
            <button
              type="submit"
              class="bg-transparent text-primary transition p-0 border-0"
            >
              <i class="flaticon-search-interface-symbol"></i>
            </button>
          </form>
          <button
            class="dot-btn lh-1 position-relative top-3 bg-transparent border-0 shadow-none p-0 transition d-inline-block"
            type="button"
          >
            <i class="flaticon-dots"></i>
          </button>
        </div>
      </div>
      <div class="card-body p-15 p-sm-20 p-md-25">
        <div class="table-responsive">
          <table class="table text-nowrap align-middle mb-0">
            <thead>
              <tr>
                <th
                  scope="col"
                  class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 ps-0"
                >
                  USER NAME
                </th>
                <th
                  scope="col"
                  class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
                >
                  EMAIL
                </th>
                <th
                  scope="col"
                  class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
                >
                  PHONE
                </th>
                <th
                  scope="col"
                  class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
                >
                  LOCATIONS
                </th>
                <th
                  scope="col"
                  class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
                >
                  JOINING DATE
                </th>
                <th
                  scope="col"
                  class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
                >
                  PROJECTS
                </th>
                <th
                  scope="col"
                  class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 text-end pe-0"
                ></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th
                  class="shadow-none lh-1 fw-medium text-black-emphasis title ps-0"
                >
                  <div class="d-flex align-items-center">
                    <div class="form-check mb-0">
                      <input
                        class="form-check-input shadow-none"
                        type="checkbox"
                      />
                    </div>
                    <div
                      class="d-flex align-items-center ms-5 fs-md-15 fs-lg-16"
                    >
                      <img
                        src="../../../assets/images/user/user3.jpg"
                        class="rounded-circle me-8"
                        width="24"
                        height="24"
                        alt="user"
                      />
                      J. Ronan
                    </div>
                  </div>
                </th>
                <td class="shadow-none lh-1 fw-medium text-primary">
                  ronanjohnny@gmail.com
                </td>
                <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                  +1-222-463-6429
                </td>
                <td class="shadow-none lh-1 fw-medium text-muted">USA</td>
                <td class="shadow-none lh-1 fw-medium text-muted">
                  18 Mar, 2024
                </td>
                <td class="shadow-none lh-1 fw-medium text-muted">20</td>
                <td
                  class="shadow-none lh-1 fw-medium text-body-tertiary text-end pe-0"
                >
                  <div class="dropdown">
                    <button
                      class="dropdown-toggle lh-1 bg-transparent border-0 shadow-none p-0 transition"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <i class="flaticon-dots"></i>
                    </button>
                    <ul class="dropdown-menu">
                      <li>
                        <a
                          class="dropdown-item d-flex align-items-center"
                          href="javascript:void(0);"
                        >
                          <i
                            class="flaticon-view lh-1 me-8 position-relative top-1"
                          ></i>
                          View
                        </a>
                      </li>
                      <li>
                        <a
                          class="dropdown-item d-flex align-items-center"
                          href="javascript:void(0);"
                        >
                          <i
                            class="flaticon-pen lh-1 me-8 position-relative top-1"
                          ></i>
                          Edit
                        </a>
                      </li>
                      <li>
                        <a
                          class="dropdown-item d-flex align-items-center"
                          href="javascript:void(0);"
                        >
                          <i
                            class="flaticon-delete lh-1 me-8 position-relative top-1"
                          ></i>
                          Delete
                        </a>
                      </li>
                    </ul>
                  </div>
                </td>
              </tr>
              <tr>
                <th
                  class="shadow-none lh-1 fw-medium text-black-emphasis title ps-0"
                >
                  <div class="d-flex align-items-center">
                    <div class="form-check mb-0">
                      <input
                        class="form-check-input shadow-none"
                        type="checkbox"
                      />
                    </div>
                    <div
                      class="d-flex align-items-center ms-5 fs-md-15 fs-lg-16"
                    >
                      <img
                        src="../../../assets/images/user/user12.jpg"
                        class="rounded-circle me-8"
                        width="24"
                        height="24"
                        alt="user"
                      />
                      J. Pinkman
                    </div>
                  </div>
                </th>
                <td class="shadow-none lh-1 fw-medium text-primary">
                  pinky321@gmail.com
                </td>
                <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                  +1-329-461-2435
                </td>
                <td class="shadow-none lh-1 fw-medium text-muted">Singapore</td>
                <td class="shadow-none lh-1 fw-medium text-muted">
                  17 Mar, 2024
                </td>
                <td class="shadow-none lh-1 fw-medium text-muted">10</td>
                <td
                  class="shadow-none lh-1 fw-medium text-body-tertiary text-end pe-0"
                >
                  <div class="dropdown">
                    <button
                      class="dropdown-toggle lh-1 bg-transparent border-0 shadow-none p-0 transition"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <i class="flaticon-dots"></i>
                    </button>
                    <ul class="dropdown-menu">
                      <li>
                        <a
                          class="dropdown-item d-flex align-items-center"
                          href="javascript:void(0);"
                        >
                          <i
                            class="flaticon-view lh-1 me-8 position-relative top-1"
                          ></i>
                          View
                        </a>
                      </li>
                      <li>
                        <a
                          class="dropdown-item d-flex align-items-center"
                          href="javascript:void(0);"
                        >
                          <i
                            class="flaticon-pen lh-1 me-8 position-relative top-1"
                          ></i>
                          Edit
                        </a>
                      </li>
                      <li>
                        <a
                          class="dropdown-item d-flex align-items-center"
                          href="javascript:void(0);"
                        >
                          <i
                            class="flaticon-delete lh-1 me-8 position-relative top-1"
                          ></i>
                          Delete
                        </a>
                      </li>
                    </ul>
                  </div>
                </td>
              </tr>
              <tr>
                <th
                  class="shadow-none lh-1 fw-medium text-black-emphasis title ps-0"
                >
                  <div class="d-flex align-items-center">
                    <div class="form-check mb-0">
                      <input
                        class="form-check-input shadow-none"
                        type="checkbox"
                      />
                    </div>
                    <div
                      class="d-flex align-items-center ms-5 fs-md-15 fs-lg-16"
                    >
                      <img
                        src="../../../assets/images/user/user13.jpg"
                        class="rounded-circle me-8"
                        width="24"
                        height="24"
                        alt="user"
                      />
                      A. Carter
                    </div>
                  </div>
                </th>
                <td class="shadow-none lh-1 fw-medium text-primary">
                  angela326421@gmail.com
                </td>
                <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                  +1-112-743-3234
                </td>
                <td class="shadow-none lh-1 fw-medium text-muted">Australia</td>
                <td class="shadow-none lh-1 fw-medium text-muted">
                  17 Mar, 2024
                </td>
                <td class="shadow-none lh-1 fw-medium text-muted">15</td>
                <td
                  class="shadow-none lh-1 fw-medium text-body-tertiary text-end pe-0"
                >
                  <div class="dropdown">
                    <button
                      class="dropdown-toggle lh-1 bg-transparent border-0 shadow-none p-0 transition"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <i class="flaticon-dots"></i>
                    </button>
                    <ul class="dropdown-menu">
                      <li>
                        <a
                          class="dropdown-item d-flex align-items-center"
                          href="javascript:void(0);"
                          ><i
                            class="flaticon-view lh-1 me-8 position-relative top-1"
                          ></i>
                          View</a
                        >
                      </li>
                      <li>
                        <a
                          class="dropdown-item d-flex align-items-center"
                          href="javascript:void(0);"
                          ><i
                            class="flaticon-pen lh-1 me-8 position-relative top-1"
                          ></i>
                          Edit</a
                        >
                      </li>
                      <li>
                        <a
                          class="dropdown-item d-flex align-items-center"
                          href="javascript:void(0);"
                          ><i
                            class="flaticon-delete lh-1 me-8 position-relative top-1"
                          ></i>
                          Delete</a
                        >
                      </li>
                    </ul>
                  </div>
                </td>
              </tr>
              <tr>
                <th
                  class="shadow-none lh-1 fw-medium text-black-emphasis title ps-0"
                >
                  <div class="d-flex align-items-center">
                    <div class="form-check mb-0">
                      <input
                        class="form-check-input shadow-none"
                        type="checkbox"
                      />
                    </div>
                    <div
                      class="d-flex align-items-center ms-5 fs-md-15 fs-lg-16"
                    >
                      <img
                        src="../../../assets/images/user/user8.jpg"
                        class="rounded-circle me-8"
                        width="24"
                        height="24"
                        alt="user"
                      />
                      V. Loper
                    </div>
                  </div>
                </th>
                <td class="shadow-none lh-1 fw-medium text-primary">
                  lopervic@gmail.com
                </td>
                <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                  +1-232-453-6929
                </td>
                <td class="shadow-none lh-1 fw-medium text-muted">Japan</td>
                <td class="shadow-none lh-1 fw-medium text-muted">
                  16 Mar, 2024
                </td>
                <td class="shadow-none lh-1 fw-medium text-muted">51</td>
                <td
                  class="shadow-none lh-1 fw-medium text-body-tertiary text-end pe-0"
                >
                  <div class="dropdown">
                    <button
                      class="dropdown-toggle lh-1 bg-transparent border-0 shadow-none p-0 transition"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <i class="flaticon-dots"></i>
                    </button>
                    <ul class="dropdown-menu">
                      <li>
                        <a
                          class="dropdown-item d-flex align-items-center"
                          href="javascript:void(0);"
                        >
                          <i
                            class="flaticon-view lh-1 me-8 position-relative top-1"
                          ></i>
                          View
                        </a>
                      </li>
                      <li>
                        <a
                          class="dropdown-item d-flex align-items-center"
                          href="javascript:void(0);"
                        >
                          <i
                            class="flaticon-pen lh-1 me-8 position-relative top-1"
                          ></i>
                          Edit
                        </a>
                      </li>
                      <li>
                        <a
                          class="dropdown-item d-flex align-items-center"
                          href="javascript:void(0);"
                        >
                          <i
                            class="flaticon-delete lh-1 me-8 position-relative top-1"
                          ></i>
                          Delete
                        </a>
                      </li>
                    </ul>
                  </div>
                </td>
              </tr>
              <tr>
                <th
                  class="shadow-none lh-1 fw-medium text-black-emphasis title ps-0"
                >
                  <div class="d-flex align-items-center">
                    <div class="form-check mb-0">
                      <input
                        class="form-check-input shadow-none"
                        type="checkbox"
                      />
                    </div>
                    <div
                      class="d-flex align-items-center ms-5 fs-md-15 fs-lg-16"
                    >
                      <img
                        src="../../../assets/images/user/user1.jpg"
                        class="rounded-circle me-8"
                        width="24"
                        height="24"
                        alt="user"
                      />
                      W. White
                    </div>
                  </div>
                </th>
                <td class="shadow-none lh-1 fw-medium text-primary">
                  whitespace@gmail.com
                </td>
                <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                  +1-721-467-6125
                </td>
                <td class="shadow-none lh-1 fw-medium text-muted">Canada</td>
                <td class="shadow-none lh-1 fw-medium text-muted">
                  15 Mar, 2024
                </td>
                <td class="shadow-none lh-1 fw-medium text-muted">13</td>
                <td
                  class="shadow-none lh-1 fw-medium text-body-tertiary text-end pe-0"
                >
                  <div class="dropdown">
                    <button
                      class="dropdown-toggle lh-1 bg-transparent border-0 shadow-none p-0 transition"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <i class="flaticon-dots"></i>
                    </button>
                    <ul class="dropdown-menu">
                      <li>
                        <a
                          class="dropdown-item d-flex align-items-center"
                          href="javascript:void(0);"
                        >
                          <i
                            class="flaticon-view lh-1 me-8 position-relative top-1"
                          ></i>
                          View
                        </a>
                      </li>
                      <li>
                        <a
                          class="dropdown-item d-flex align-items-center"
                          href="javascript:void(0);"
                        >
                          <i
                            class="flaticon-pen lh-1 me-8 position-relative top-1"
                          ></i>
                          Edit
                        </a>
                      </li>
                      <li>
                        <a
                          class="dropdown-item d-flex align-items-center"
                          href="javascript:void(0);"
                        >
                          <i
                            class="flaticon-delete lh-1 me-8 position-relative top-1"
                          ></i>
                          Delete
                        </a>
                      </li>
                    </ul>
                  </div>
                </td>
              </tr>
              <tr>
                <th
                  class="shadow-none lh-1 fw-medium text-black-emphasis title ps-0"
                >
                  <div class="d-flex align-items-center">
                    <div class="form-check mb-0">
                      <input
                        class="form-check-input shadow-none"
                        type="checkbox"
                      />
                    </div>
                    <div
                      class="d-flex align-items-center ms-5 fs-md-15 fs-lg-16"
                    >
                      <img
                        src="../../../assets/images/user/user4.jpg"
                        class="rounded-circle me-8"
                        width="24"
                        height="24"
                        alt="user"
                      />
                      T. Skyler
                    </div>
                  </div>
                </th>
                <td class="shadow-none lh-1 fw-medium text-primary">
                  skylertina213@gmail.com
                </td>
                <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                  +1-345-463-1234
                </td>
                <td class="shadow-none lh-1 fw-medium text-muted">
                  New Zealand
                </td>
                <td class="shadow-none lh-1 fw-medium text-muted">
                  14 Mar, 2024
                </td>
                <td class="shadow-none lh-1 fw-medium text-muted">32</td>
                <td
                  class="shadow-none lh-1 fw-medium text-body-tertiary text-end pe-0"
                >
                  <div class="dropdown">
                    <button
                      class="dropdown-toggle lh-1 bg-transparent border-0 shadow-none p-0 transition"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <i class="flaticon-dots"></i>
                    </button>
                    <ul class="dropdown-menu">
                      <li>
                        <a
                          class="dropdown-item d-flex align-items-center"
                          href="javascript:void(0);"
                        >
                          <i
                            class="flaticon-view lh-1 me-8 position-relative top-1"
                          ></i>
                          View
                        </a>
                      </li>
                      <li>
                        <a
                          class="dropdown-item d-flex align-items-center"
                          href="javascript:void(0);"
                        >
                          <i
                            class="flaticon-pen lh-1 me-8 position-relative top-1"
                          ></i>
                          Edit
                        </a>
                      </li>
                      <li>
                        <a
                          class="dropdown-item d-flex align-items-center"
                          href="javascript:void(0);"
                        >
                          <i
                            class="flaticon-delete lh-1 me-8 position-relative top-1"
                          ></i>
                          Delete
                        </a>
                      </li>
                    </ul>
                  </div>
                </td>
              </tr>
              <tr>
                <th
                  class="shadow-none lh-1 fw-medium text-black-emphasis title ps-0"
                >
                  <div class="d-flex align-items-center">
                    <div class="form-check mb-0">
                      <input
                        class="form-check-input shadow-none"
                        type="checkbox"
                      />
                    </div>
                    <div
                      class="d-flex align-items-center ms-5 fs-md-15 fs-lg-16"
                    >
                      <img
                        src="../../../assets/images/user/user7.jpg"
                        class="rounded-circle me-8"
                        width="24"
                        height="24"
                        alt="user"
                      />
                      K. Hong
                    </div>
                  </div>
                </th>
                <td class="shadow-none lh-1 fw-medium text-primary">
                  hong3215@gmail.com
                </td>
                <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                  +1-134-345-5926
                </td>
                <td class="shadow-none lh-1 fw-medium text-muted">Germany</td>
                <td class="shadow-none lh-1 fw-medium text-muted">
                  14 Mar, 2024
                </td>
                <td class="shadow-none lh-1 fw-medium text-muted">14</td>
                <td
                  class="shadow-none lh-1 fw-medium text-body-tertiary text-end pe-0"
                >
                  <div class="dropdown">
                    <button
                      class="dropdown-toggle lh-1 bg-transparent border-0 shadow-none p-0 transition"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <i class="flaticon-dots"></i>
                    </button>
                    <ul class="dropdown-menu">
                      <li>
                        <a
                          class="dropdown-item d-flex align-items-center"
                          href="javascript:void(0);"
                        >
                          <i
                            class="flaticon-view lh-1 me-8 position-relative top-1"
                          ></i>
                          View
                        </a>
                      </li>
                      <li>
                        <a
                          class="dropdown-item d-flex align-items-center"
                          href="javascript:void(0);"
                        >
                          <i
                            class="flaticon-pen lh-1 me-8 position-relative top-1"
                          ></i>
                          Edit
                        </a>
                      </li>
                      <li>
                        <a
                          class="dropdown-item d-flex align-items-center"
                          href="javascript:void(0);"
                        >
                          <i
                            class="flaticon-delete lh-1 me-8 position-relative top-1"
                          ></i>
                          Delete
                        </a>
                      </li>
                    </ul>
                  </div>
                </td>
              </tr>
              <tr>
                <th
                  class="shadow-none lh-1 fw-medium text-black-emphasis title ps-0"
                >
                  <div class="d-flex align-items-center">
                    <div class="form-check mb-0">
                      <input
                        class="form-check-input shadow-none"
                        type="checkbox"
                      />
                    </div>
                    <div
                      class="d-flex align-items-center ms-5 fs-md-15 fs-lg-16"
                    >
                      <img
                        src="../../../assets/images/user/user10.jpg"
                        class="rounded-circle me-8"
                        width="24"
                        height="24"
                        alt="user"
                      />
                      B. Traversy
                    </div>
                  </div>
                </th>
                <td class="shadow-none lh-1 fw-medium text-primary">
                  traversymedia32@gmail.com
                </td>
                <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                  +1-252-463-6567
                </td>
                <td class="shadow-none lh-1 fw-medium text-muted">Canada</td>
                <td class="shadow-none lh-1 fw-medium text-muted">
                  14 Mar, 2024
                </td>
                <td class="shadow-none lh-1 fw-medium text-muted">52</td>
                <td
                  class="shadow-none lh-1 fw-medium text-body-tertiary text-end pe-0"
                >
                  <div class="dropdown">
                    <button
                      class="dropdown-toggle lh-1 bg-transparent border-0 shadow-none p-0 transition"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <i class="flaticon-dots"></i>
                    </button>
                    <ul class="dropdown-menu">
                      <li>
                        <a
                          class="dropdown-item d-flex align-items-center"
                          href="javascript:void(0);"
                        >
                          <i
                            class="flaticon-view lh-1 me-8 position-relative top-1"
                          ></i>
                          View
                        </a>
                      </li>
                      <li>
                        <a
                          class="dropdown-item d-flex align-items-center"
                          href="javascript:void(0);"
                        >
                          <i
                            class="flaticon-pen lh-1 me-8 position-relative top-1"
                          ></i>
                          Edit
                        </a>
                      </li>
                      <li>
                        <a
                          class="dropdown-item d-flex align-items-center"
                          href="javascript:void(0);"
                        >
                          <i
                            class="flaticon-delete lh-1 me-8 position-relative top-1"
                          ></i>
                          Delete
                        </a>
                      </li>
                    </ul>
                  </div>
                </td>
              </tr>
              <tr>
                <th
                  class="shadow-none lh-1 fw-medium text-black-emphasis title ps-0"
                >
                  <div class="d-flex align-items-center">
                    <div class="form-check mb-0">
                      <input
                        class="form-check-input shadow-none"
                        type="checkbox"
                      />
                    </div>
                    <div
                      class="d-flex align-items-center ms-5 fs-md-15 fs-lg-16"
                    >
                      <img
                        src="../../../assets/images/user/user6.jpg"
                        class="rounded-circle me-8"
                        width="24"
                        height="24"
                        alt="user"
                      />
                      J. Lopage
                    </div>
                  </div>
                </th>
                <td class="shadow-none lh-1 fw-medium text-primary">
                  jennifer321456@gmail.com
                </td>
                <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                  +1-822-763-6421
                </td>
                <td class="shadow-none lh-1 fw-medium text-muted">USA</td>
                <td class="shadow-none lh-1 fw-medium text-muted">
                  13 Mar, 2024
                </td>
                <td class="shadow-none lh-1 fw-medium text-muted">24</td>
                <td
                  class="shadow-none lh-1 fw-medium text-body-tertiary text-end pe-0"
                >
                  <div class="dropdown">
                    <button
                      class="dropdown-toggle lh-1 bg-transparent border-0 shadow-none p-0 transition"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <i class="flaticon-dots"></i>
                    </button>
                    <ul class="dropdown-menu">
                      <li>
                        <a
                          class="dropdown-item d-flex align-items-center"
                          href="javascript:void(0);"
                        >
                          <i
                            class="flaticon-view lh-1 me-8 position-relative top-1"
                          ></i>
                          View
                        </a>
                      </li>
                      <li>
                        <a
                          class="dropdown-item d-flex align-items-center"
                          href="javascript:void(0);"
                        >
                          <i
                            class="flaticon-pen lh-1 me-8 position-relative top-1"
                          ></i>
                          Edit
                        </a>
                      </li>
                      <li>
                        <a
                          class="dropdown-item d-flex align-items-center"
                          href="javascript:void(0);"
                        >
                          <i
                            class="flaticon-delete lh-1 me-8 position-relative top-1"
                          ></i>
                          Delete
                        </a>
                      </li>
                    </ul>
                  </div>
                </td>
              </tr>
              <tr>
                <th
                  class="shadow-none lh-1 fw-medium text-black-emphasis title ps-0"
                >
                  <div class="d-flex align-items-center">
                    <div class="form-check mb-0">
                      <input
                        class="form-check-input shadow-none"
                        type="checkbox"
                      />
                    </div>
                    <div
                      class="d-flex align-items-center ms-5 fs-md-15 fs-lg-16"
                    >
                      <img
                        src="../../../assets/images/user/user11.jpg"
                        class="rounded-circle me-8"
                        width="24"
                        height="24"
                        alt="user"
                      />
                      D. Jonathon
                    </div>
                  </div>
                </th>
                <td class="shadow-none lh-1 fw-medium text-primary">
                  dipperjohnny@gmail.com
                </td>
                <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                  +1-232-453-5429
                </td>
                <td class="shadow-none lh-1 fw-medium text-muted">Japan</td>
                <td class="shadow-none lh-1 fw-medium text-muted">
                  13 Mar, 2024
                </td>
                <td class="shadow-none lh-1 fw-medium text-muted">46</td>
                <td
                  class="shadow-none lh-1 fw-medium text-body-tertiary text-end pe-0"
                >
                  <div class="dropdown">
                    <button
                      class="dropdown-toggle lh-1 bg-transparent border-0 shadow-none p-0 transition"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <i class="flaticon-dots"></i>
                    </button>
                    <ul class="dropdown-menu">
                      <li>
                        <a
                          class="dropdown-item d-flex align-items-center"
                          href="javascript:void(0);"
                        >
                          <i
                            class="flaticon-view lh-1 me-8 position-relative top-1"
                          ></i>
                          View
                        </a>
                      </li>
                      <li>
                        <a
                          class="dropdown-item d-flex align-items-center"
                          href="javascript:void(0);"
                        >
                          <i
                            class="flaticon-pen lh-1 me-8 position-relative top-1"
                          ></i>
                          Edit
                        </a>
                      </li>
                      <li>
                        <a
                          class="dropdown-item d-flex align-items-center"
                          href="javascript:void(0);"
                        >
                          <i
                            class="flaticon-delete lh-1 me-8 position-relative top-1"
                          ></i>
                          Delete
                        </a>
                      </li>
                    </ul>
                  </div>
                </td>
              </tr>
              <tr>
                <th
                  class="shadow-none lh-1 fw-medium text-black-emphasis title ps-0"
                >
                  <div class="d-flex align-items-center">
                    <div class="form-check mb-0">
                      <input
                        class="form-check-input shadow-none"
                        type="checkbox"
                      />
                    </div>
                    <div
                      class="d-flex align-items-center ms-5 fs-md-15 fs-lg-16"
                    >
                      <img
                        src="../../../assets/images/user/user10.jpg"
                        class="rounded-circle me-8"
                        width="24"
                        height="24"
                        alt="user"
                      />
                      D. Gardner
                    </div>
                  </div>
                </th>
                <td class="shadow-none lh-1 fw-medium text-primary">
                  gardnerdaddy@gmail.com
                </td>
                <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                  +1-272-163-6469
                </td>
                <td class="shadow-none lh-1 fw-medium text-muted">Russia</td>
                <td class="shadow-none lh-1 fw-medium text-muted">
                  13 Mar, 2024
                </td>
                <td class="shadow-none lh-1 fw-medium text-muted">32</td>
                <td
                  class="shadow-none lh-1 fw-medium text-body-tertiary text-end pe-0"
                >
                  <div class="dropdown">
                    <button
                      class="dropdown-toggle lh-1 bg-transparent border-0 shadow-none p-0 transition"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <i class="flaticon-dots"></i>
                    </button>
                    <ul class="dropdown-menu">
                      <li>
                        <a
                          class="dropdown-item d-flex align-items-center"
                          href="javascript:void(0);"
                        >
                          <i
                            class="flaticon-view lh-1 me-8 position-relative top-1"
                          ></i>
                          View
                        </a>
                      </li>
                      <li>
                        <a
                          class="dropdown-item d-flex align-items-center"
                          href="javascript:void(0);"
                        >
                          <i
                            class="flaticon-pen lh-1 me-8 position-relative top-1"
                          ></i>
                          Edit
                        </a>
                      </li>
                      <li>
                        <a
                          class="dropdown-item d-flex align-items-center"
                          href="javascript:void(0);"
                        >
                          <i
                            class="flaticon-delete lh-1 me-8 position-relative top-1"
                          ></i>
                          Delete
                        </a>
                      </li>
                    </ul>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div
          class="pagination-area d-md-flex mt-15 mt-sm-20 mt-md-25 justify-content-between align-items-center"
        >
          <p class="mb-0 text-paragraph">
            Showing <span class="fw-bold">11</span> out of
            <span class="fw-bold">134</span> results
          </p>
          <nav class="mt-15 mt-md-0">
            <ul class="pagination mb-0">
              <li class="page-item">
                <a class="page-link" href="#" aria-label="Previous">
                  <i class="flaticon-chevron-1"></i>
                </a>
              </li>
              <li class="page-item">
                <a class="page-link active" href="#">1</a>
              </li>
              <li class="page-item">
                <a class="page-link" href="#">2</a>
              </li>
              <li class="page-item">
                <a class="page-link" href="#">3</a>
              </li>
              <li class="page-item">
                <a class="page-link" href="#" aria-label="Next">
                  <i class="flaticon-chevron"></i>
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
    <CreateNewUserModal />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import CreateNewUserModal from "./CreateNewUserModal.vue";

export default defineComponent({
  name: "UsersList",
  components: {
    CreateNewUserModal,
  },
});
</script>
